import React, { useEffect, useState } from "react";
import TopHeader from '../components/TopHeader';
import Footer from '../components/Footer';

function HomePage() {
	const [showVideo, setShowVideo] = useState(false);

	const scrollContentNavRight = () => {
		let deviceWidth = window.innerWidth;
		const teamList = document.querySelector(".team__list");
		const teamItem = document.querySelector(".team__list--item:first-child");
		if (deviceWidth <= 600) {
			teamList.scrollLeft += teamItem.offsetWidth;
		} else {
			teamList.scrollLeft += (parseInt(teamItem.offsetWidth) + 21);
		}
	}
	const scrollContentNavLeft = () => {
		let deviceWidth = window.innerWidth;
		const teamList = document.querySelector(".team__list");
		const teamItem = document.querySelector(".team__list--item:first-child");
		if (deviceWidth <= 600) {
			teamList.scrollLeft -= teamItem.offsetWidth;
		} else {
			teamList.scrollLeft -= (parseInt(teamItem.offsetWidth) + 21);
		}
	}

	const scrollRoadmapNavRight = () => {
		let deviceWidth = window.innerWidth;
		const teamList = document.querySelector(".roadmap__list");
		const teamItem = document.querySelector(".roadmap__list--item:first-child");
		if (deviceWidth <= 600) {
			teamList.scrollLeft += teamItem.offsetWidth;
		} else {
			teamList.scrollLeft += teamItem.offsetWidth;
		}
	}


	const scrollRoadmapNavLeft = () => {
		console.log("click");
		let deviceWidth = window.innerWidth;
		const teamList = document.querySelector(".roadmap__list");
		const teamItem = document.querySelector(".roadmap__list--item:first-child");
		if (deviceWidth <= 600) {
			teamList.scrollLeft -= teamItem.offsetWidth;
		} else {
			teamList.scrollLeft -= teamItem.offsetWidth;
		}
	}

	return (
		<div className='container'>
			<TopHeader />

			<section className='header'>
				<div className='header__container'>
					{
						//<h2 className='header__welcome'>Welcome to</h2>
					}
					<h1 className='header__title'>Footballers & Partners</h1>
					<p className='header__info'>Footballers & Partners helps young footballers earn off-field income by managing their image rights and by offering direct marketing advice. They also create promotional opportunities for companies to leverage the players' popularity.
					</p>
					<a href="https://app.wcapes.com" target={'_blank'}><button className='yellow-btn'>JOIN DAO</button></a>
				</div>

				<img className='header__pic' src='./../images/header-pic.png' alt='header pic' />
				<div className='header__menu'>

					<button className='header__icon-btn'>
						<a target='_blank' href="https://www.instagram.com/worldchampionsapes/">
							<img src='./../icons/instagram-ico.png' alt='menu' />
						</a>
					</button>

					<button className='header__icon-btn'>
						<a target='_blank' href='https://twitter.com/Wcapes_official'>
							<img src='./../icons/twitter-ico.png' alt='menu' />
						</a>
					</button>

					<button className='header__icon-btn'>
						<a target='_blank' href='https://t.me/wcapes_official'>
							<img src='./../icons/send-ico.png' alt='menu' />
						</a>
					</button>

					<button className='header__icon-btn'>
						<a target='_blank' href='https://www.linkedin.com/company/wcapes/'>
							<img src='./../icons/linkedin-ico.svg' alt='menu' />
						</a>
					</button>

					<button className='header__icon-btn'>
						<a target='_blank' href='https://discord.com/invite/uRMMdaNKFv'>
							<img src='./../icons/controller-ico.png' alt='menu' />
						</a>
					</button>

					<button className='header__icon-btn'>
						<a target='_blank' href='https://www.youtube.com/channel/UCnR1xMO5Kly0aeVihT5T7Og'>
							<img src='./../icons/youtube-ico.png' alt='menu' />
						</a>
					</button>
				</div>
			</section>

			<section className='company'>
				<div className='company__container'>
					<div className='company__pic'>
						<img className='how-it-work-pic' src='./../images/how-it-work2.png' alt='Team pic' />
					</div>

					<div className='company__info'>
						<h4 className='company__info--subtitle'>How It Works</h4>
						<p className='company__info--desc'>Discovers young football talents and manages their image rights using data analysis, performance evaluations, and direct observation. We also offer marketing consultancy services to ensure effective management of our clients' image rights. We provide opportunities for young talents to earn off-field income and for companies to promote their products through the popularity of emerging football players.</p>
						<a href="/portfolio" ><button className='yellow-btn'>DISCOVER THE PORTFOLIO</button></a>
					</div>

				</div>
			</section>

			<section className='the-company'>
				<div className='the-company__container'>
					<div className='the-company__info'>
						<h4 className='the-company__info--subtitle'>The Company</h4>
						<p className='the-company__info--desc'>Footballers and Partners is a company specializing in the management and negotiation of sponsorship and partnership contracts for professional football players or those projected to become professional. We offer sponsorship services to help players find advantageous agreements with brands and companies, and partnership services to help our clients develop joint projects with companies operating in the sports industry. Our experience, expertise, and network of contacts in the sports industry allow us to provide a personalized, high-quality service for each individual client.</p>
					</div>
					<div className='company__pic'>
						<img className='how-it-work-pic' src='./../images/HomeCard.png' alt='Team pic' />
					</div>

				</div>
			</section>

			{
				showVideo ?
					<div className='video-wrapper'>
						<iframe width="560" height="315" src="https://stream.wcapes.com/streaming/video1.mp4" title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					</div>
					:
					<section className='video'>
						<div className='video__content'>
							<h3 className='video__title'>Company meetings</h3>
							<p className='video__desc'>Login to DAO to attend society meetings</p>
							<button className='video__btn' onClick={() => {
								setShowVideo(true)
							}}>
								<img src='./../icons/play-ico.svg' alt='play icon' />
							</button>
						</div>
						<img className='video__bg' src='./../images/company-meetings.png' alt='company meetings video' />
					</section>
			}

			<section className='company gray'>
				<div className='company__container'>
					<div className='company__pic'>
						<img className='how-it-work-pic' src='./../images/attracting-sponsor.png' alt='Team pic' />
					</div>
					<div className='company__info'>
						<h4 className='company__info--subtitle'>Attracting Sponsors</h4>
						<p className='company__info--desc'>Our sponsorship service helps football players find beneficial agreements with brands and companies looking to associate their name with the player's image. Thanks to our network of contacts and experience in the industry, we can help our clients obtain sponsorship contracts that meet their needs and the marketing objectives of partner companies. Our goal is to maximize our clients' earning potential and build long-term relationships with brands and companies that share their values and vision.</p>
					</div>
				</div>
			</section>

			<section className='swot-analysis'>
				<div className='swot-analysis__container'>
					<h3 className='swot-analysis__title'>Swot Analysis</h3>

					<div className='swot-analysis__list'>
						<div className='swot-analysis__list--item'>
							<h4 className='title'>STRENGHTS</h4>
							<ul className='desc' style={{ marginLeft: '2rem' }}>
								<li>Professional team composed of agents and former football players</li>
								<li>Presence in different countries</li>
								<li>Big interest from sponsors</li>
								<li>Corrected: Large marketing agencies are not interested in this market segment.</li>
							</ul>
						</div>

						<div className='swot-analysis__list--item'>
							<h4 className='title'>WEAKNESSES</h4>
							<ul className='desc' style={{ marginLeft: '2rem' }}>
								<li>A mature market with numerous football agents and marketing agencies can create some confusion when starting out in the industry. This is due to the fear of losing influence over their clients by these agents and marketing agencies.</li>
								<li>Monitor the conduct of young football players and consistently oversee their performance across various football teams.</li>
							</ul>
						</div>

						<div className='swot-analysis__list--item'>
							<h4 className='title'>OPPORTUNITIES</h4>
							<ul className='desc' style={{ marginLeft: '2rem' }}>
								<li>Fill a market which is underestimated</li>
								<li>Possibility to establish a large community of young football players in multiple countries with minimal costs.</li>
								<li>Coordinate international marketing campaigns for sponsors</li>
							</ul>
						</div>

						<div className='swot-analysis__list--item'>
							<h4 className='title'>THREATS</h4>
							<ul className='desc' style={{ marginLeft: '2rem' }}>
								<li>No entries barrieres</li>
								<li>Football agents can perceive a threat to our operations and set up barriers.</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
{
	/*
				<section className='team'>
				<div className='team__container'>
					<h3 className='team__title'>Team</h3>

					<div className='team__list'>
						<div className='team__list--item'>
							<img className='team-pic' src='./../images/team/Zenga-cerchio-2.png' alt='' />
							<h4 className='name'>WALTER ZENGA</h4>
							<h5 className='position'>Ambassador & Football Legend</h5>
							<p className='desc'>Walter Zenga is an Italian football manager and former player who most recently managed the Serie A club Cagliari. He also served as a goalkeeper for Inter Milan and the Italian national team for a significant period of time.</p>
							<a target={'_blank'} href='https://www.linkedin.com/in/walter-zenga-42370614b/' className='yellow-outline-btn' type='button'>View Linked In Profile</a>
						</div>

						<div className='team__list--item'>
							<img className='team-pic' src='./../images/team/Picasso-cerchio-1.png' alt='' />
							<h4 className='name'>LUCA PICASSO</h4>
							<h5 className='position'>Advisor & Shareholder</h5>
							<p className='desc'>Entrepreneur in different fields but mainly in oil&gas with a long career in the most important Italian entrepreneurs association.</p>
							<a target={'_blank'} href='https://www.linkedin.com/in/luca-picasso-2562236' className='yellow-outline-btn' type='button'>View Linked In Profile</a>
						</div>
						<div className='team__list--item'>
							<img className='team-pic' src='./../images/team/Mangano-cerchio-1.png' alt='' />
							<h4 className='name'>DANIELE MANGANO</h4>
							<h5 className='position'>Robotic Business and Blockchain Expert</h5>
							<p className='desc'>Daniele Mangano is a serial entrepreneur who is active with his companies in the areas of industrial automation, biometric recognition, blockchain technology, and digital currencies.</p>
							<a target={'_blank'} href='https://www.linkedin.com/in/mangano-daniele' className='yellow-outline-btn' type='button'>View Linked In Profile</a>
						</div>
						<div className='team__list--item'>
							<img className='team-pic' src='./../images/team/logo.png' alt='' />
							<h4 className='name'>Sublocks</h4>
							<h5 className='position'>BLOCKCHAIN AGENCY</h5>
							<p className='desc'>Sublocks Srl is a blockchain technology company that offers a wide range of innovative products and solutions, including a smart contract platform, decentralized blockchain-based applications, tokenomics services, and custom NFT collections</p>
							<a target={'_blank'} href='https://www.wcapes.com/' className='yellow-outline-btn' type='button'>View Website</a>
						</div>
					</div>
					<div className='team__nav'>
							<button className='team__nav--btn' onClick={scrollContentNavLeft}>
									<img src='./../icons/left-btn.svg' alt='' />
							</button>

							<button className='team__nav--btn' onClick={scrollContentNavRight}>
									<img src='./../icons/right-btn.svg' alt='' />
							</button>
					</div>
				</div>
			</section>
	
	*/
}



			<Footer />
		</div>
	)
}

export default HomePage;