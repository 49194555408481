import React, { useState } from 'react'

export default function TopHeader() {
	const [isShowMenu, setIsShowMenu] = useState(false);
	return (
		<nav className='main-nav'>
			<div className='main-nav__container'>
				<a href="../"><img className='main-nav__logo' src='./../images/header-logo.png' alt='Top logo' /></a>

				<button className='main-nav__icon-btn menubutton' style={{ color: "#000" }} onClick={() => { setIsShowMenu(true) }}>
					<span style={{ color: "#000" }}>MENU</span> <img src='./../images/menu.png' alt='menu' />
				</button>

			</div>
			{
				isShowMenu &&
				<>
					<div className='main-nav__menu'>
						<div className='main-nav__menu--header'>
							<a href="../"><img className='menu-logo' src='./../images/header-logo.png' alt='Top logo' /></a>
							<h2 className='menu-title'>Footballers & Partner</h2>
							{
								//<a className='menu-email' href='mailto: info@wcapes.com'>info@wcapes.com</a>
							}
							<button className='btn-close'
								onClick={() => { setIsShowMenu(false) }}
							>×</button>

						</div>

						<div className='main-nav__menu--body'>
							<div className='item'>
								<h4 className='item-title'>PORTFOLIO</h4>
								<div className='list'>
									<a className='list--item' href='/porfolio'>Discover Portfolio</a>
								</div>
							</div>

							<div className='item'>
								<h4 className='item-title'>DAO</h4>
								<div className='list'>
									<a className='list--item' href='https://app.wcapes.com/'>DAO APP</a>
								</div>
							</div>


							<div className='item'>
								<h4 className='item-title'>DOWNLOAD AREA</h4>
								<div className='list'>
									<a className='list--item' href='/download'>Other Files</a>
								</div>
							</div>


						</div>
					</div>
					<div className='main-nav__menu-bg' onClick={() => { setIsShowMenu(false) }}></div>
				</>
			}
		</nav>
	)
}