import React from 'react';

export default function Footer() {
	return (
		<footer className='main-footer'>
			<a href='#top' className='topButton'></a>
			<a style={{
							display: "block",
							margin: "auto",
							textAlign: "center"
						}} href='../'><img className='main-footer__logo' src='./../images/header-logo.png' alt='Top logo' />
			</a>
			<a className='email' href='mailto:info@wcapes.com'>info@wcapes.com</a>

			<div className='main-footer__container'>
				<div className='main-footer__social'>
					<a href='https://www.instagram.com/worldchampionsapes/' target={'_blank'} className='main-footer__social__icon-btn'>
						<img src='./../icons/instagram-ico.png' alt='menu' />
					</a>

					<a href='https://twitter.com/Wcapes_official' target={'_blank'} className='main-footer__social__icon-btn'>
						<img src='./../icons/twitter-ico.png' alt='menu' />
					</a>

					<a href='https://t.me/wcapes_official' target={'_blank'} className='main-footer__social__icon-btn'>
						<img src='./../icons/send-ico.png' alt='menu' />
					</a>

					<a href='https://www.linkedin.com/company/wcapes/' target={'_blank'} className='main-footer__social__icon-btn'>
						<img src='./../icons/linkedin-ico.svg' alt='menu' />
					</a>

					<a href='https://discord.com/invite/uRMMdaNKFv' target={'_blank'} className='main-footer__social__icon-btn'>
						<img src='./../icons/controller-ico.png' alt='menu' />
					</a>

					<a href='https://www.youtube.com/channel/UCnR1xMO5Kly0aeVihT5T7Og' target={'_blank'} className='main-footer__social__icon-btn'>
						<img src='./../icons/youtube-ico.png' alt='menu' />
					</a>
				</div>
			</div>
		</footer>
	)
}