import React from 'react';
import TopHeader from '../components/TopHeader';
import Footer from '../components/Footer';

function Portfolio() {

	return (
		<div className='container'>
			<TopHeader />
			<section style={{
				display: 'block',
				marginTop: "100px",
			}}>
				<h1 style={{
					fontSize: "45px",
					padding: "60px 0",
					textAlign: "center"
				}}>Footballers <span style={{ color: "#eac44a" }}>Portfolio</span></h1>
			
				<p style={{
					width: "90%",
					maxWidth: "900px",
					margin: "auto",
					display: "block",
					fontSize: "16px",
					fontWeight: "500",
					margin: "0 auto 50px",
					color: "#fff"
				}}>
					Here you can discover the talented young footballers we represent and learn how we help them earn income off the field through image rights management and marketing advice. We also provide companies with opportunities to promote their products or services using our players' popularity.
				</p>

				<table style={{borderSpacing: "0px", marginBottom: "100px"}}>
					<tr>
							<th className='porftolioColumnImage'>
								Photo
							</th>
							<th className='porftolioColumnName'>
								Name
							</th>
							<th className='porftolioColumnDescription'>
								Description 
							</th>
							<th className='porftolioColumnDownload'>
								Download 
							</th>
					</tr>
					<tr>
							<td className='porftolioColumnImage'><img src='./../images/vargas.png' className='portfolioImage'></img></td>
							<td className='porftolioColumnName'>Kerwin Vargas</td>
							<td className='porftolioColumnDescription'>
								<b>Name:</b> Kerwin Andrés Calderón Vargas
								<br></br>
								<b>Height:</b> 1.79m
								<br></br>
								<b>Weight:</b> 70kg
								<br></br>
								<b>Actual club:</b> Charlotte FC
							</td>
							<td className='porftolioColumnDownload'><u><a href="https://www.instagram.com/officialkerwinvargas/" style={{color: "#fff"}} >Instagram</a></u></td>
					</tr>
					<tr>
							<td className='porftolioColumnImage'><img src='./../images/gustavosa.png' className='portfolioImage'></img></td>
							<td className='porftolioColumnName'>Gustavo Sá</td>
							<td className='porftolioColumnDescription'>
								<b>Nome:</b> Gustavo Filipe Alves Freitas Azevedo Sá
								<br></br>
								<b>Height:</b> 1.87m
								<br></br>
								<b>Weight:</b> 78kg
								<br></br>
								<b>Actual club:</b> FC Famalicão
							</td>
							<td className='porftolioColumnDownload'><u><a href="https://www.instagram.com/gustavo_sa_10/" style={{color: "#fff"}} >Instagram</a></u></td>
					</tr>
					
				</table>

			</section>
			<Footer />
		</div>
	)
}

export default Portfolio;